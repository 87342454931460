import { ResourceOption } from '../../../../services/bookingApi';
import { CloudProvider, CloudRegion } from '../../../../services/clustersApi';
import { BOOKING_STATUS, BookingPackageOutput, Currency, ResourceType } from '../../../../utils/booking-utils';
import { ClusterResourcesSummary } from '../../../../utils/booking-utils';
import { Cluster, PrivateRegion, isClusterFreeTier, isClusterHybridCloud } from '../../../../utils/cluster-utils';
import { isAtLeastVersion } from '../../../../utils/helpers';
import { CLOUD_PROVIDER_GEOGRAPHY_MAP, CLOUD_PROVIDER_MAP } from '../../constants';
import { ClusterConfiguration } from '../ClusterConfiguration/ClusterConfigurationContext';
import { MIN_DISK_SPACE } from '../ClusterConfiguration/ClusterDiskSpaceControl';
import { MIN_NODES } from '../ClusterConfiguration/ClusterNodesControl';
import { ScaleType } from '../ClusterConfiguration/ClusterScaleTabs';
import { ClusterTemplateType } from '../ClusterTemplateList';

export type PackageResourceSet = [
  number /* 1 | 2 | 4 | 8 | 16 | 32 | 64 | 128 | 256 | 512 | ... */,
  number /* 500 | 1000 | 2000 | 4000 | 8000 | 16000 | ... */,
  number /* 4 | 8 | 16 | 32 | 64 | 128 | 256 | ... */,
];

export type BookingPackages = BookingPackageOutput[];

const NARROWED_RESOURCE_TYPE = ['ram', 'cpu', 'disk'] as const;
const RAM_INDEX = NARROWED_RESOURCE_TYPE.indexOf('ram');

export const toPackageResourcesObject = (bookingPackage?: BookingPackageOutput | null) => {
  const findResourceOption = (resourceType: ResourceType) =>
    bookingPackage?.resource_configuration.find(
      ({ resource_option }) => resource_option?.resource_type === resourceType,
    );

  return {
    get memory() {
      return findResourceOption('ram')?.amount;
    },
    get cpu() {
      return findResourceOption('cpu')?.amount;
    },
    get disk() {
      return findResourceOption('disk')?.amount;
    },
    getClusterConfiguration(): ClusterConfiguration {
      return {
        memory: this.memory ?? 0,
        cpu: this.cpu ?? 0,
        disk: this.disk ?? 0,
        complimentaryDisk: 0,
        additionalDisk: MIN_DISK_SPACE,
        nodes: MIN_NODES,
      };
    },
  };
};

/**
 * Returns a list of resource packages, e.g.: [[1, 500, 4], [2, 500, 8], ... ]
 * Internal use only.
 */
export function toResourcePackages(packages: BookingPackages) {
  return packages.reduce<PackageResourceSet[]>((acc, value) => {
    if (value.status === BOOKING_STATUS.PLANNED) {
      return acc;
    }
    const { memory, cpu, disk } = toPackageResourcesObject(value);
    if (memory && cpu && disk) {
      acc.push([memory, cpu, disk]);
    }
    return acc;
  }, []);
}

/**
 * Returns the lowest resource set package from a list of packages.
 */
export function findLowestResourceSetPackage(packages: BookingPackages) {
  const [lowestMemory] = getPackagesMemoryScale(packages);
  const [lowestCpu] = getPackagesResourceScaleByMemoryValue(packages, 'cpu', lowestMemory);
  const [lowestDisk] = getPackagesResourceScaleByMemoryValue(packages, 'disk', lowestMemory);

  return findPackageFromResourceSet(packages, [lowestMemory, lowestCpu, lowestDisk]);
}

export const FREE_TEMPLATE_NAMES = ['free2'];
export const STANDARD_TEMPLATE_NAMES = ['gpx1', 'gpx2'];
export const BOOKING_PACKAGE_TYPE_FREE = 'free';
export const BOOKING_PACKAGE_TYPE_PAID = 'paid';

export function getPackageResourceSetById(packages: BookingPackages, id: string): ClusterConfiguration | null {
  const value = packages.find((p) => p.id === id);
  if (!value) {
    return null;
  }
  return toPackageResourcesObject(value).getClusterConfiguration();
}

function getTemplatePackageByType(
  packages: BookingPackages,
  template: ClusterTemplateType,
): BookingPackageOutput | undefined {
  let value;
  const names = template === 'free' ? FREE_TEMPLATE_NAMES : STANDARD_TEMPLATE_NAMES;
  for (const name of names) {
    value = packages.find((p) => p.name === name);
    if (value) {
      break;
    }
  }
  return value;
}

export function getPackageResourceSetByName(
  packages: BookingPackages,
  template: ClusterTemplateType,
): ClusterConfiguration {
  let value = getTemplatePackageByType(packages, template);
  if (!value && template === 'standard') {
    value = packages.at(0);
  }
  return toPackageResourcesObject(value).getClusterConfiguration();
}

export const toClusterResourcesObject = (clusterResources?: ClusterResourcesSummary | null) => ({
  get memory() {
    const value = clusterResources?.ram;
    if (!value) {
      return 0;
    }
    return value.base;
  },
  get cpu() {
    const value = clusterResources?.cpu;
    if (!value) {
      return 0;
    }
    return value.base;
  },
  get disk() {
    const value = clusterResources?.disk;
    if (!value) {
      return 0;
    }
    return value.base;
  },
  get complimentaryDisk() {
    const value = clusterResources?.disk;
    if (!value) {
      return 0;
    }
    // Complimentary disk is only available for clusters whose disk size was synthetically changed to make up
    // for a reduction of the disk size in the free tier.
    return value.complimentary;
  },
  get additionalDisk() {
    const value = clusterResources?.disk;
    if (!value) {
      return 0;
    }
    return value.extra;
  },
});

export const toClusterReservedResourcesObject = (clusterResources?: ClusterResourcesSummary | null) => ({
  get memory() {
    const value = clusterResources?.ram;
    if (!value) {
      return 0;
    }
    return value.reserved;
  },
  get cpu() {
    const value = clusterResources?.cpu;
    if (!value) {
      return 0;
    }
    return value.reserved;
  },
  get disk() {
    const value = clusterResources?.disk;
    if (!value) {
      return 0;
    }
    return value.reserved;
  },
});

export const getPackageResourceSetByConfiguration = (cluster: Cluster): ClusterConfiguration =>
  Object.assign({ nodes: cluster.configuration?.num_nodes! }, toClusterResourcesObject(cluster.resources));

/**
 * Returns the package id for a given resource set.
 */
export function findPackageFromResourceSet(
  packages: BookingPackages,
  packageResourceSet: PackageResourceSet,
): BookingPackageOutput | undefined {
  if (packageResourceSet.every((value) => value === 0)) {
    return;
  }
  const values = toResourcePackages(packages);
  const packageIndex = values.findIndex((resources) =>
    resources.every((resource, i) => resource === packageResourceSet[i]),
  );
  return packages[packageIndex];
}

export function filterPackagesByClusterAndActive(cluster: Cluster, packages: BookingPackages): BookingPackages {
  const { memory, cpu, disk } = getPackageResourceSetByConfiguration(cluster);
  const packageResourceSet: PackageResourceSet = [memory, cpu, disk];
  const currentPackage = findPackageFromResourceSet(packages, packageResourceSet);
  if (!currentPackage) {
    throw new Error(`package not found for resource set: ${packageResourceSet.join(' | ')}`);
  }
  return packages.filter((pkg) => pkg.status === BOOKING_STATUS.ACTIVE || pkg === currentPackage);
}

/**
 * Returns an array of *unique*, sorted memory values for a given set of packages.
 */
export function getPackagesMemoryScale(packages: BookingPackages, excludeFreePackage = true) {
  let values = toResourcePackages(packages)
    .map((value) => value[RAM_INDEX]) // extract memory values
    .sort((a, b) => a - b); // sort ascending

  if (excludeFreePackage) {
    const freeTemplateConfig = getPackageResourceSetByName(packages, 'free');
    // Filters out free tier package from the scale
    values = values.filter((value) => value !== freeTemplateConfig.memory);
  }

  return [...new Set(values)]; // remove duplicates
}

/**
 * Returns an array (range) of *unique*, sorted resource values for a given memory value.
 * This is useful to display the possible values of a resource given a chosen memory step.
 */
export function getPackagesResourceScaleByMemoryValue(
  packages: BookingPackages,
  resourceType: Extract<ResourceType, 'cpu' | 'disk'>,
  memoryValue: number,
) {
  const values = toResourcePackages(packages);
  const index = NARROWED_RESOURCE_TYPE.indexOf(resourceType);

  return [
    ...new Set(
      values
        .reduce<number[]>((acc, range) => {
          if (range[RAM_INDEX] === memoryValue) {
            acc.push(range[index]);
          }
          return acc;
        }, [])
        .sort((a, b) => a - b),
    ),
  ];
}

export function fromHzToGHz(value: number): string {
  return (value / 1000).toFixed(1);
}

export const FRACTIONAL_TO_BASIC_MONETARY_UNIT_FACTOR = 100 * 1000; // 100 cents in a dollar, 1000 milli-cents in a cent
export const AVG_DAYS_PER_MONTH = 30.5;
export const HOURS_IN_A_DAY = 24;

export type PricedEntityRates = {
  hourlyItem: number;
  hourlyTotal: number;
  monthlyTotal: number;
  currency: Currency;
} | null;

export function getPricedEntityRates({
  entity,
  multiplier = 1,
}: {
  entity?: BookingPackageOutput | ResourceOption;
  multiplier?: number;
}): PricedEntityRates {
  const hourlyRatePerUnit = entity?.unit_int_price_per_hour ?? null;
  if (typeof hourlyRatePerUnit !== 'number') {
    return null;
  }
  // Hourly rate of one item is in milli-cents, so we divide by 100 * 1000 to get the dollar amount
  const hourlyItemPrice = hourlyRatePerUnit / FRACTIONAL_TO_BASIC_MONETARY_UNIT_FACTOR;
  // Get total rate by applying the hourly rate multiplier
  const hourlyRate = hourlyRatePerUnit * multiplier;
  // Hourly rate is in milli-cents, so we divide by 100 * 1000 to get the dollar amount
  const hourlyPrice = hourlyRate / FRACTIONAL_TO_BASIC_MONETARY_UNIT_FACTOR;
  // 24 hours x 30.5 days (average number of days in a month)
  const monthlyPrice = (hourlyRate * HOURS_IN_A_DAY * AVG_DAYS_PER_MONTH) / FRACTIONAL_TO_BASIC_MONETARY_UNIT_FACTOR;
  // Get the currency or default to dollars
  const currency = entity?.currency ?? 'usd';
  // Return the hourly and monthly rates
  return { hourlyItem: hourlyItemPrice, hourlyTotal: hourlyPrice, monthlyTotal: monthlyPrice, currency };
}

export type PricedEntityPrices = {
  hourlyItem: string;
  hourlyTotal: string;
  monthlyTotal: string;
} | null;

export function getPricedEntityPrices({
  pricedEntityRates,
}: {
  pricedEntityRates: PricedEntityRates;
}): PricedEntityPrices {
  if (!pricedEntityRates) {
    return null;
  }
  const currency = pricedEntityRates.currency.toUpperCase();
  const hourlyPriceFormatter = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency,
    currencyDisplay: 'narrowSymbol',
    minimumFractionDigits: 2,
    maximumFractionDigits: 5,
  });
  const monthlyPriceFormatter = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency,
    currencyDisplay: 'narrowSymbol',
    minimumFractionDigits: 2,
  });

  return {
    hourlyItem: hourlyPriceFormatter.format(pricedEntityRates.hourlyItem),
    hourlyTotal: hourlyPriceFormatter.format(pricedEntityRates.hourlyTotal),
    monthlyTotal: monthlyPriceFormatter.format(pricedEntityRates.monthlyTotal),
  };
}

/**
 * Returns the disk resource option that is status active (2) with an hourly price, otherwise undefined.
 */
export const findDiskResourceOptionWithHourlyPrice = (data?: ResourceOption[]): ResourceOption | undefined =>
  data
    ?.filter(
      ({ name, status, unit_int_price_per_hour }: ResourceOption) =>
        name === 'disk' && status === BOOKING_STATUS.ACTIVE && unit_int_price_per_hour != null,
    )
    ?.at(0);

export const getCloudRegionReadableName = (provider: CloudProvider, region: CloudRegion) => {
  if (provider === CLOUD_PROVIDER_MAP.PRIVATE) {
    return null;
  }

  return (
    Object.values(CLOUD_PROVIDER_GEOGRAPHY_MAP[provider])
      .flat()
      .find((r) => r.value === region)?.name ?? null
  );
};

/**
 * Returns boolean indicating if the cluster is valid for scaling up.
 * Clusters can't be vertically scaled when:
 *  - They run on Azure with Operator V1; or
 *  - It's a hybrid cloud cluster and its private region doesn't have volume expansion capability
 */
export const isValidConfigForScalingVertically = ({
  cluster,
  hybridCloudEnv,
}: {
  cluster?: Cluster;
  hybridCloudEnv?: PrivateRegion;
} = {}) => {
  if (!cluster) {
    return false;
  }

  if (isClusterHybridCloud(cluster) && !hybridCloudEnv?.status?.capabilities.volumeExpansion) {
    return false;
  }

  if (cluster.configuration?.operator_version === 'V2') {
    return true;
  }

  return cluster.cloud_provider !== CLOUD_PROVIDER_MAP.AZURE;
};

/**
 * Returns boolean indicating if the cluster is valid for scaling out.
 * Below 1.7 can't be horizontally scaled unless having 3 vcpu (3000 millicpu).
 * Free tier clusters can't be horizontally scaled either.
 */
export const isValidConfigForScalingHorizontally = (cluster?: Cluster) => {
  if (!cluster) {
    return false;
  }
  if (!isAtLeastVersion('1.7', cluster.version)) {
    return getPackageResourceSetByConfiguration(cluster).cpu >= 3000;
  }
  return !isClusterFreeTier(cluster);
};

export function getInitialScaleType(args: {
  cluster?: Cluster;
  hybridCloudEnv?: PrivateRegion;
}): ScaleType | undefined {
  if (!isValidConfigForScalingVertically(args)) {
    return 'nodes';
  }
  if (!isValidConfigForScalingHorizontally(args.cluster)) {
    return 'resources';
  }
}

/**
 * Clusters can only downscale horizontally if they are running on Operator V2 and are at least version 1.9.
 */
export const isValidConfigForDownscalingHorizontally = (cluster?: Cluster) => {
  if (!cluster) {
    return false;
  }

  return cluster.configuration?.operator_version === 'V2' && isAtLeastVersion('1.9', cluster.version);
};

export function isFreeTierAllowed(bookingPackages: BookingPackages) {
  // Check if there is a free booking package that is deactivated and then exclude it too
  return getTemplatePackageByType(bookingPackages, 'free')?.status === BOOKING_STATUS.ACTIVE;
}
