import { CloudProvider, CloudRegion, KubernetesDistribution } from '../../services/clustersApi';
import { isProductionEnv } from '../../utils/utils';

export type ProviderRegion = {
  value: CloudRegion;
  name: string;
  flag: string;
  devOnly?: boolean; // Only show in dev environment or for users with developer role
};

export const GCP_REGION_EU_WEST_3 = {
  value: 'europe-west3',
  name: 'Frankfurt',
  flag: '🇩🇪',
} as const satisfies ProviderRegion;
export const GCP_REGION_US_EAST_4 = {
  value: 'us-east4',
  name: 'N. Virginia',
  flag: '🇺🇸',
} as const satisfies ProviderRegion;
export const AZURE_REGION_US_EAST_1 = {
  value: 'eastus',
  name: 'Virginia',
  flag: '🇺🇸',
} as const satisfies ProviderRegion;
export const AZURE_REGION_SOUTHEAST_ASIA = {
  value: 'southeastasia',
  name: 'Singapore',
  flag: '🇸🇬',
} as const satisfies ProviderRegion;
export const AZURE_REGION_GERMANY_WESTCENTRAL = {
  value: 'germanywestcentral',
  name: 'Frankfurt',
  flag: '🇩🇪',
} as const satisfies ProviderRegion;
export const AZURE_REGION_UK_SOUTH = {
  value: 'uksouth',
  name: 'London',
  flag: '🇬🇧',
} as const satisfies ProviderRegion;
export const CLOUD_REGION_EU_CENTRAL = {
  value: 'eu-central-1',
  name: 'Frankfurt',
  flag: '🇩🇪',
} as const satisfies ProviderRegion;
export const CLOUD_REGION_EU_WEST = {
  value: 'eu-west-1',
  name: 'Ireland',
  flag: '🇮🇪',
} as const satisfies ProviderRegion;
export const CLOUD_REGION_EU_WEST_2 = {
  value: 'eu-west-2',
  name: 'London',
  flag: '🇬🇧',
} as const satisfies ProviderRegion;
export const CLOUD_REGION_US_EAST = {
  value: 'us-east-1',
  name: 'N. Virginia',
  flag: '🇺🇸',
} as const satisfies ProviderRegion;
export const CLOUD_REGION_US_WEST = {
  value: 'us-west-1',
  name: 'N. California',
  flag: '🇺🇸',
} as const satisfies ProviderRegion;
export const CLOUD_REGION_US_WEST_2 = {
  value: 'us-west-2',
  name: 'Oregon',
  flag: '🇺🇸',
} as const satisfies ProviderRegion;
export const CLOUD_REGION_AP_NORTHEAST = {
  value: 'ap-northeast-1',
  name: 'Tokyo',
  flag: '🇯🇵',
} as const satisfies ProviderRegion;
export const CLOUD_REGION_AP_SOUTHEAST = {
  value: 'ap-southeast-1',
  name: 'Singapore',
  flag: '🇸🇬',
} as const satisfies ProviderRegion;
export const CLOUD_REGION_AP_SOUTHEAST_2 = {
  value: 'ap-southeast-2',
  name: 'Sydney',
  flag: '🇦🇺',
} as const satisfies ProviderRegion;
export const CLOUD_REGION_AP_SOUTH = {
  value: 'ap-south-1',
  name: 'India',
  flag: '🇮🇳',
  devOnly: true,
} as const satisfies ProviderRegion;

export const CLOUD_PROVIDER_MAP = {
  AWS: 'aws',
  GCP: 'gcp',
  AZURE: 'azure',
  PRIVATE: 'private', // Hybrid Cloud
} as const;

type K8SDistributionMap = Record<Uppercase<KubernetesDistribution>, KubernetesDistribution>;

export const K8S_DISTRIBUTION_MAP = {
  GCP: 'gcp',
  AWS: 'aws',
  AZURE: 'azure',
  UNKNOWN: 'unknown',
  DO: 'do', // Digital Ocean
  SCALEWAY: 'scaleway',
  OPENSHIFT: 'openshift',
  LINODE: 'linode',
  CIVO: 'civo',
  OCI: 'oci', //Oracle Cloud Infrastructure
  OVHCLOUD: 'ovhcloud',
  STACKIT: 'stackit',
  VULTR: 'vultr',
  K3S: 'k3s',
} as const satisfies K8SDistributionMap;

export type ProviderGeography =
  | 'North America'
  | 'South America'
  | 'Europe'
  | 'Middle east'
  | 'Africa'
  | 'Australia'
  | 'Asia';

type CloudProviderGeographyMap = Readonly<
  Record<Exclude<CloudProvider, 'private'>, Partial<Record<ProviderGeography, readonly ProviderRegion[]>>>
>;

export type QdrantConfigurationInputs = {
  apiKeySecretKey: string;
  apiKeySecretName: string;
  readOnlyApiKeySecretKey: string;
  readOnlyApiKeySecretName: string;
  logLevel: string;
  tlsCertSecretKey: string;
  tlsCertSecretName: string;
  tlsKeySecretKey: string;
  tlsKeySecretName: string;
  replicationFactor: number | null;
  optimizerCPUBudget: number | null;
  asyncScorer: boolean;
  writeConsistencyFactor: number | null;
  onDisk: boolean;
};

export const CLOUD_PROVIDER_GEOGRAPHY_MAP: CloudProviderGeographyMap = isProductionEnv
  ? {
      [CLOUD_PROVIDER_MAP.AWS]: {
        'North America': [CLOUD_REGION_US_EAST, CLOUD_REGION_US_WEST, CLOUD_REGION_US_WEST_2],
        Europe: [CLOUD_REGION_EU_CENTRAL, CLOUD_REGION_EU_WEST, CLOUD_REGION_EU_WEST_2],
        Asia: [CLOUD_REGION_AP_NORTHEAST, CLOUD_REGION_AP_SOUTHEAST, CLOUD_REGION_AP_SOUTH],
        Australia: [CLOUD_REGION_AP_SOUTHEAST_2],
      },
      [CLOUD_PROVIDER_MAP.GCP]: {
        'North America': [GCP_REGION_US_EAST_4],
        Europe: [GCP_REGION_EU_WEST_3],
      },
      [CLOUD_PROVIDER_MAP.AZURE]: {
        'North America': [AZURE_REGION_US_EAST_1],
        Europe: [AZURE_REGION_GERMANY_WESTCENTRAL, AZURE_REGION_UK_SOUTH],
        Asia: [AZURE_REGION_SOUTHEAST_ASIA],
      },
    }
  : {
      [CLOUD_PROVIDER_MAP.AWS]: {
        Europe: [CLOUD_REGION_EU_CENTRAL],
      },
      [CLOUD_PROVIDER_MAP.GCP]: {
        Europe: [GCP_REGION_EU_WEST_3],
      },
      [CLOUD_PROVIDER_MAP.AZURE]: {
        Europe: [AZURE_REGION_GERMANY_WESTCENTRAL],
      },
    };

export const CLOUD_PROVIDERS = [
  CLOUD_PROVIDER_MAP.AWS,
  CLOUD_PROVIDER_MAP.GCP,
  CLOUD_PROVIDER_MAP.AZURE,
  CLOUD_PROVIDER_MAP.PRIVATE,
] as const;

export type FreeTierCloudProvider = 'aws' | 'gcp';
export const FREE_TIER_CLOUD_PROVIDERS: FreeTierCloudProvider[] = [CLOUD_PROVIDER_MAP.AWS, CLOUD_PROVIDER_MAP.GCP];
export const FREE_TIER_GEOGRAPHIES: ProviderGeography[] = ['North America', 'Europe'];

export const CLOUD_PROVIDER_IMGS: Record<CloudProvider, { src: string; themed: boolean; tooltip: string }> = {
  [CLOUD_PROVIDER_MAP.AWS]: {
    src: '/images/aws.svg',
    themed: true,
    tooltip: 'AWS',
  },
  [CLOUD_PROVIDER_MAP.GCP]: {
    src: '/images/gcp.svg',
    themed: false,
    tooltip: 'Google Cloud Platform',
  },
  [CLOUD_PROVIDER_MAP.AZURE]: {
    src: '/images/azure.svg',
    themed: false,
    tooltip: 'Azure',
  },
  [CLOUD_PROVIDER_MAP.PRIVATE]: {
    src: '',
    themed: true,
    tooltip: 'Hybrid Cloud',
  },
} as const;

type ImageSrc = { dark: string; light: string } | string;

type ImageData = (
  | {
      src: string;
      themed: true;
    }
  | {
      src: ImageSrc;
      themed: false;
    }
) & {
  tooltip: string;
};

export const K8S_DISTRIBUTION_IMGS: Record<KubernetesDistribution, ImageData> = {
  [K8S_DISTRIBUTION_MAP.AWS]: {
    src: '/aws.svg',
    themed: true,
    tooltip: 'AWS',
  },
  [K8S_DISTRIBUTION_MAP.GCP]: {
    src: '/gcp.svg',
    themed: false,
    tooltip: 'Google Cloud Platform',
  },
  [K8S_DISTRIBUTION_MAP.AZURE]: {
    src: '/azure.svg',
    themed: false,
    tooltip: 'Azure',
  },
  [K8S_DISTRIBUTION_MAP.CIVO]: {
    src: '/images/providers/civo.svg',
    themed: false,
    tooltip: 'CIVO',
  },
  [K8S_DISTRIBUTION_MAP.DO]: {
    src: '/images/providers/digitalocean.svg',
    themed: false,
    tooltip: 'DigitalOcean',
  },
  [K8S_DISTRIBUTION_MAP.SCALEWAY]: {
    src: {
      light: '/images/providers/scaleway-light.svg',
      dark: '/images/providers/scaleway-dark.svg',
    },
    themed: false,
    tooltip: 'Scaleway',
  },
  [K8S_DISTRIBUTION_MAP.VULTR]: {
    src: '/images/providers/vultr.svg',
    themed: false,
    tooltip: 'Vultr',
  },
  [K8S_DISTRIBUTION_MAP.LINODE]: {
    src: '/images/providers/linode.svg',
    themed: false,
    tooltip: 'Linode',
  },
  [K8S_DISTRIBUTION_MAP.OPENSHIFT]: {
    src: '/images/providers/openshift.svg',
    themed: false,
    tooltip: 'OpenShift',
  },
  [K8S_DISTRIBUTION_MAP.OCI]: {
    src: '/images/providers/oracle.svg',
    themed: false,
    tooltip: 'Oracle Cloud Infrastructure',
  },
  [K8S_DISTRIBUTION_MAP.OVHCLOUD]: {
    src: {
      dark: '/images/providers/ovh-dark.svg',
      light: '/images/providers/ovh-light.svg',
    },
    themed: false,
    tooltip: 'OVH Cloud',
  },
  [K8S_DISTRIBUTION_MAP.STACKIT]: {
    src: '/images/providers/stackit.svg',
    themed: false,
    tooltip: 'Stackit',
  },
  [K8S_DISTRIBUTION_MAP.K3S]: {
    src: '/images/providers/k3s.svg',
    themed: false,
    tooltip: 'K3s',
  },
  [K8S_DISTRIBUTION_MAP.UNKNOWN]: {
    src: '',
    themed: true,
    tooltip: 'Hybrid Cloud',
  },
} as const;
export type ProviderEnvConfig = {
  enabled: boolean;
  availableRegions: readonly ProviderRegion[];
  defaultRegion: CloudRegion;
};

type ProviderConfig = {
  production: ProviderEnvConfig;
  staging: ProviderEnvConfig;
};

type CloudProvidersConfig = {
  providers: Record<CloudProvider, ProviderConfig>;
};

export const CLOUD_PROVIDER_CONFIG: CloudProvidersConfig = {
  providers: {
    [CLOUD_PROVIDER_MAP.AWS]: {
      production: {
        defaultRegion: CLOUD_REGION_US_EAST.value,
        enabled: true,
        availableRegions: [
          CLOUD_REGION_US_EAST,
          CLOUD_REGION_US_WEST,
          CLOUD_REGION_US_WEST_2,
          CLOUD_REGION_EU_CENTRAL,
          CLOUD_REGION_EU_WEST,
          CLOUD_REGION_EU_WEST_2,
          CLOUD_REGION_AP_NORTHEAST,
          CLOUD_REGION_AP_SOUTHEAST,
          CLOUD_REGION_AP_SOUTHEAST_2,
          CLOUD_REGION_AP_SOUTH,
        ],
      },
      staging: {
        enabled: true,
        defaultRegion: CLOUD_REGION_EU_CENTRAL.value,
        availableRegions: [CLOUD_REGION_EU_CENTRAL],
      },
    },
    [CLOUD_PROVIDER_MAP.GCP]: {
      production: {
        enabled: true,
        defaultRegion: GCP_REGION_US_EAST_4.value,
        availableRegions: [GCP_REGION_US_EAST_4, GCP_REGION_EU_WEST_3],
      },
      staging: {
        enabled: true,
        defaultRegion: GCP_REGION_EU_WEST_3.value,
        availableRegions: [GCP_REGION_EU_WEST_3],
      },
    },
    [CLOUD_PROVIDER_MAP.AZURE]: {
      production: {
        enabled: true,
        availableRegions: [
          AZURE_REGION_US_EAST_1,
          AZURE_REGION_GERMANY_WESTCENTRAL,
          AZURE_REGION_SOUTHEAST_ASIA,
          AZURE_REGION_UK_SOUTH,
        ],
        defaultRegion: AZURE_REGION_US_EAST_1.value,
      },
      staging: {
        enabled: true,
        availableRegions: [AZURE_REGION_GERMANY_WESTCENTRAL],
        defaultRegion: AZURE_REGION_GERMANY_WESTCENTRAL.value,
      },
    },
    [CLOUD_PROVIDER_MAP.PRIVATE]: {
      production: { enabled: true, availableRegions: [], defaultRegion: '' as CloudRegion },
      staging: { enabled: true, availableRegions: [], defaultRegion: '' as CloudRegion },
    },
  },
} as const;

export const MILLICENTS_TO_DOLLAR_OR_EURO_FACTOR = 100 * 1000;
export const AVG_DAYS_PER_MONTH = 30.5;
export const HOURS_IN_A_DAY = 24;

export const QDRANT_LOG_LEVELS = { TRACE: 'TRACE', DEBUG: 'DEBUG', INFO: 'INFO', WARN: 'WARN', ERROR: 'ERROR' };
