import DiskIcon from '@mui/icons-material/Album';
import CpuIcon from '@mui/icons-material/Calculate';
import MemoryIcon from '@mui/icons-material/Memory';
import { Box, Theme, Tooltip } from '@mui/material';
import { compare } from 'fast-json-patch';
import isEmpty from 'lodash/isEmpty';
import { ReactNode } from 'react';
import { ByteSizeFormatterOptions, humanReadableSizeFormatter } from './Metrics/utils';
import {
  CLOUD_PROVIDER_GEOGRAPHY_MAP,
  CLOUD_PROVIDER_IMGS,
  CLOUD_PROVIDER_MAP,
  FREE_TIER_CLOUD_PROVIDERS,
  FREE_TIER_GEOGRAPHIES,
  FreeTierCloudProvider,
  K8S_DISTRIBUTION_IMGS,
  ProviderGeography,
  ProviderRegion,
  QdrantConfigurationInputs,
} from './constants';
import { CloudProvider, CloudRegion, KubernetesDistribution } from '../../services/clustersApi';
import { ComponentSchema } from '../../utils/api-schema-utils';
import { ClusterResource, QdrantConfiguration } from '../../utils/cluster-utils';
import { getRandomElement } from '../../utils/utils';
import { ImageThemed } from '../Common/ImageThemed';

type ResourceType = ComponentSchema<'ResourceType'> | 'ram_rss' | 'ram_qdrant_rss' | 'ram_cache';

export function formatResourcesOptions(amount: number, type?: ResourceType, nodes = 1) {
  if (nodes > 1) {
    switch (type) {
      case 'cpu':
        return `vCPUs: ${amount / 1000} x ${nodes} = ${(amount / 1000) * nodes}`;
      case 'ram':
      case 'ram_rss':
      case 'disk':
        return `${type.toUpperCase()}: ${amount}GB x ${nodes} = ${amount * nodes}GB`;
      default:
        throw new Error('Invalid resource option type');
    }
  }
  switch (type) {
    case 'cpu':
      return `vCPUs: ${amount / 1000}`;
    case 'ram':
    case 'ram_rss':
    case 'disk':
      return `${type.toUpperCase()}: ${amount}GB`;
    default:
      throw new Error('Invalid resource option type');
  }
}

export function getResourceIcon(type?: ResourceType, color: 'secondary' | 'info' = 'secondary', size = '0.7em') {
  switch (type) {
    case 'cpu':
      return <CpuIcon color={color} sx={{ verticalAlign: 'bottom', width: size, height: size }} />;
    case 'ram':
    case 'ram_rss':
      return <MemoryIcon color={color} sx={{ verticalAlign: 'bottom', width: size, height: size }} />;
    case 'disk':
      return <DiskIcon color={color} sx={{ verticalAlign: 'bottom', width: size, height: size }} />;
    default:
      throw new Error('Invalid resource type');
  }
}

/**
 * Returns img jsx element with cloud provider logo adapted to the theme mode change
 */
export function getProviderImage(cloudProvider: CloudProvider, theme: object) {
  const style = {
    width: '100%',
    height: 'auto',
  };
  const imgData = CLOUD_PROVIDER_IMGS[cloudProvider];
  const img = imgData.themed ? (
    <ImageThemed src={imgData.src} theme={theme} alt={cloudProvider} style={style} />
  ) : (
    <img alt={cloudProvider} src={imgData.src} style={style} />
  );

  return (
    <Tooltip title={imgData.tooltip}>
      <Box>{img}</Box>
    </Tooltip>
  );
}

export function getK8sDistributionImage(
  k8sDistribution: KubernetesDistribution,
  theme: Theme,
  errorCallback?: () => void,
) {
  const style = {
    width: '100%',
    height: 'auto',
  };
  const imgData = K8S_DISTRIBUTION_IMGS[k8sDistribution];

  // Tooltip e.g: "AWS on Hybrid Cloud"
  const tooltip = `${imgData.tooltip} on ${CLOUD_PROVIDER_IMGS[CLOUD_PROVIDER_MAP.PRIVATE].tooltip}`;

  let img: ReactNode;

  if (imgData.themed) {
    img = <ImageThemed src={imgData.src} theme={theme} alt={k8sDistribution} style={style} onError={errorCallback} />;
  } else {
    // If the image is not themed, it is a string (theme agnostic) or an object with a string for each theme
    const imgSource = typeof imgData.src === 'string' ? imgData.src : imgData.src[theme.palette.mode];
    img = <img alt={k8sDistribution} src={imgSource} style={style} onError={errorCallback} />;
  }

  return (
    <Tooltip title={tooltip}>
      <Box>{img}</Box>
    </Tooltip>
  );
}

/**
 * Makes sure the number is not too big to be represented as a JavaScript number.
 */
const checkForSafeNumber = (value: number) => {
  if (value > Number.MAX_SAFE_INTEGER) {
    throw new RangeError('The number is too big to be represented as a JavaScript number.');
  }
};

export function formatBytesToGigabytes(
  bytes: number,
  { standard = 'IEC', decimalPlaces = 1 }: ByteSizeFormatterOptions = {},
) {
  checkForSafeNumber(bytes);
  const unit = standard === 'SI' ? 1000 : 1024;
  const value = (bytes / unit ** 3).toFixed(decimalPlaces);

  return `${value} GB`;
}

/**
 * Transforms gigabytes to bytes. (1 Gigabyte = 1000^3 bytes, different from Gibibyte = 1024^3 bytes)
 */
export function transformGigabytesToBytes(gigabytes = 0) {
  return gigabytes * 1000 ** 3;
}

/**
 * Transforms bytes to gigabytes. (1.000.000.000 bytes = 1 Gigabyte = 1 GB),
 * different from 1 GiB = 1.073.741.824 bytes)
 */
export function transformBytesToGigabytes(bytes = 0) {
  return bytes / 1000 ** 3;
}

/**
 * Transforms gibibytes to bytes. (1 Gibibyte = 1024^3 bytes)
 */
export function transformGibibytesToBytes(gigabytes = 0) {
  return gigabytes * 1024 ** 3;
}

/**
 * Transforms bytes to gibibytes. (1.073.741.824 bytes = 1 Gibibyte = 1 GiB),
 */
export function transformBytesToGibibytes(bytes = 0) {
  return bytes / 1024 ** 3;
}

/**
 * Returns the total amount of a resource by adding up base, extra & complimentary.
 */
export function getResourceTotal(resource?: ClusterResource | null) {
  if (!resource) {
    return 0;
  }

  const getResource = (resourceType: keyof ClusterResource) => resource[resourceType] ?? 0;
  return getResource('base') + getResource('extra') + getResource('complimentary');
}

/**
 * Given a float value, it will format it to the amount of digits passed as `fixedTo`.
 * and will add a suffix (e.g `GB`). If the value is an integer it will return it without decimals (e.g `2 GB`).
 */
export const formatFloatWithSuffix = ({
  fixedTo,
  value = 0,
  suffix,
}: {
  fixedTo: number;
  value?: number;
  suffix?: string;
}) => {
  const readableValue = value % 1 > 0 ? parseFloat(value.toFixed(fixedTo)) : Math.trunc(value);
  let formattedValue = `${readableValue}`;
  if (suffix) {
    formattedValue += ` ${suffix}`;
  }
  return formattedValue;
};

/**
 * Formatting uses the default byte to GiBi conversion (IEC) and 2 decimal places unless specified differently.
 */
export function formatMetricAmount(type: ResourceType, metric: number, decimalPlaces = 2): string {
  if (!Number.isFinite(metric)) {
    return 'N/A';
  }
  if (type === 'cpu') {
    return `${parseFloat(metric.toFixed(decimalPlaces))} vCPUs`;
  }
  return humanReadableSizeFormatter(metric, { decimalPlaces });
}

// Utility function to remove empty objects
function removeEmptyPropertyFromObject(obj: Record<string, unknown>, key: string) {
  if (isEmpty(obj[key])) {
    const { [key]: _, ...rest } = obj;
    return rest;
  }

  return obj;
}

function updateServiceConfig(data: QdrantConfigurationInputs, config: QdrantConfiguration) {
  const updatedConfig = structuredClone(config);
  updatedConfig.service = updatedConfig.service ?? {};
  if (data.apiKeySecretKey) {
    updatedConfig.service.api_key = {
      secretKeyRef: {
        key: data.apiKeySecretKey,
        name: data.apiKeySecretName,
      },
    };
  } else {
    delete updatedConfig.service.api_key;
  }

  if (data.readOnlyApiKeySecretKey) {
    updatedConfig.service.read_only_api_key = {
      secretKeyRef: {
        key: data.readOnlyApiKeySecretKey,
        name: data.readOnlyApiKeySecretName,
      },
    };
  } else {
    delete updatedConfig.service.read_only_api_key;
  }
  return removeEmptyPropertyFromObject(updatedConfig, 'service');
}

function updateTLSConfig(data: QdrantConfigurationInputs, config: QdrantConfiguration) {
  const updatedConfig = structuredClone(config);
  updatedConfig.tls = config.tls ?? {};

  if (data.tlsCertSecretKey) {
    updatedConfig.tls.cert = {
      secretKeyRef: {
        key: data.tlsCertSecretKey,
        name: data.tlsCertSecretName,
      },
    };
  } else {
    delete updatedConfig.tls.cert;
  }

  if (data.tlsKeySecretKey) {
    updatedConfig.tls.key = {
      secretKeyRef: {
        key: data.tlsKeySecretKey,
        name: data.tlsKeySecretName,
      },
    };
  } else {
    delete updatedConfig.tls.key;
  }

  return removeEmptyPropertyFromObject(updatedConfig, 'tls');
}

function updateCollectionConfig(data: QdrantConfigurationInputs, config: QdrantConfiguration) {
  const updatedConfig = structuredClone(config);

  updatedConfig.collection = updatedConfig.collection ?? {};

  if (data.replicationFactor != null) {
    updatedConfig.collection.replication_factor = data.replicationFactor;
  } else {
    delete updatedConfig.collection.replication_factor;
  }

  if (data.writeConsistencyFactor != null) {
    updatedConfig.collection.write_consistency_factor = data.writeConsistencyFactor;
  } else {
    delete updatedConfig.collection.write_consistency_factor;
  }

  if (data.onDisk) {
    updatedConfig.collection.vectors = updatedConfig.collection.vectors ?? {};
    updatedConfig.collection.vectors.on_disk = data.onDisk;
  } else if (updatedConfig.collection.vectors) {
    delete updatedConfig.collection.vectors.on_disk;
    updatedConfig.collection = removeEmptyPropertyFromObject(updatedConfig.collection, 'vectors');
  }

  return removeEmptyPropertyFromObject(updatedConfig, 'collection');
}

function updateLogLevel(data: QdrantConfigurationInputs, config: QdrantConfiguration) {
  const updatedConfig = structuredClone(config);
  if (data.logLevel) {
    updatedConfig.log_level = data.logLevel;
  } else {
    delete updatedConfig.log_level;
  }
  return updatedConfig;
}

function updateStorageConfig(data: QdrantConfigurationInputs, config: QdrantConfiguration) {
  const updatedConfig = structuredClone(config);
  updatedConfig.storage = updatedConfig.storage ?? {};
  updatedConfig.storage.performance = updatedConfig.storage.performance ?? {};

  if (data.asyncScorer) {
    updatedConfig.storage.performance.async_scorer = data.asyncScorer;
  } else if (updatedConfig.storage.performance.async_scorer) {
    delete updatedConfig.storage.performance.async_scorer;
  }

  if (data.optimizerCPUBudget == null) {
    delete updatedConfig.storage.performance.optimizer_cpu_budget;
  } else {
    updatedConfig.storage.performance.optimizer_cpu_budget = data.optimizerCPUBudget < 0 ? 0 : data.optimizerCPUBudget;
  }

  updatedConfig.storage = removeEmptyPropertyFromObject(updatedConfig.storage, 'performance');
  return removeEmptyPropertyFromObject(updatedConfig, 'storage');
}

/**
 * It creates a QdrantConfiguration json object based on the current configuration
 * to later compare with the cluster's original qdrant config in order to output a
 * list of patches to provide to the API.
 */
export function getQdrantConfigurationPatches(
  data: QdrantConfigurationInputs,
  originalConfig?: QdrantConfiguration | null,
) {
  // Create the new config using existing configuration (in case there is anything set
  // that we are not changing within the form) and replace / remove those that
  // were changed or unset respectively.
  let newConfigObj: QdrantConfiguration = structuredClone(originalConfig) ?? {};

  newConfigObj = updateServiceConfig(data, newConfigObj);
  newConfigObj = updateTLSConfig(data, newConfigObj);
  newConfigObj = updateStorageConfig(data, newConfigObj);
  newConfigObj = updateCollectionConfig(data, newConfigObj);
  newConfigObj = updateLogLevel(data, newConfigObj);

  return compare(originalConfig ?? {}, newConfigObj);
}

/**
 * Select a random free tier Cloud Provider.
 */
export function getRandomFreeTierCloudProvider(): FreeTierCloudProvider {
  return getRandomElement(FREE_TIER_CLOUD_PROVIDERS);
}

/**
 * Finds, based on a free tier Cloud Provider, a random region that
 * is selected from a random free tier-allowed geography region).
 */
export function getRandomFreeTierRegion(provider: FreeTierCloudProvider): CloudRegion {
  const geographyRegionsMap = CLOUD_PROVIDER_GEOGRAPHY_MAP[provider];
  const freeTierGeosForProvider: ProviderGeography[] = (Object.keys(geographyRegionsMap) as ProviderGeography[]).filter(
    (geo) => FREE_TIER_GEOGRAPHIES.includes(geo),
  );
  // Select a random geography from the ones available for the free tier for that provider
  const geography = getRandomElement(freeTierGeosForProvider);

  // Select a random region from the selected geography
  const regions: readonly ProviderRegion[] = geographyRegionsMap[geography]!;
  return getRandomElement(regions).value;
}
