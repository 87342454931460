import { compare, validate } from 'compare-versions';

/*
 * Takes a value in cents and transforms it to dollars with its currency sign.
 */
export function formatCents(value = 0) {
  return `$ ${(value / 100).toFixed(2)}`;
}

type TimeUnit = 'weeks' | 'days' | 'hours' | 'minutes';
const MINUTE_IN_MS = 1000 * 60;
const HOUR_IN_MS = MINUTE_IN_MS * 60;
const DAY_IN_MS = HOUR_IN_MS * 24;
const WEEK_IN_MS = DAY_IN_MS * 7;

/**
 * Calculates and returns the relative distance in each expected unit and value (from week to minutes).
 * E.g: [
 *  { unit: 'weeks', diff: 0 },
 *  { unit: 'days', diff: 1 },
 *  { unit: 'hours', diff: 22 },
 *  { unit: 'minutes', diff: 40 },
 * ]
 */
const getOrderedTimeDifferences = (timeDifference: number): { unit: TimeUnit; diff: number }[] => {
  if (timeDifference < 0) {
    throw new Error('Expected time difference must be positive.');
  }

  return [
    { unit: 'weeks', diff: Math.floor(timeDifference / WEEK_IN_MS) },
    { unit: 'days', diff: Math.floor((timeDifference % WEEK_IN_MS) / DAY_IN_MS) },
    { unit: 'hours', diff: Math.floor((timeDifference % DAY_IN_MS) / HOUR_IN_MS) },
    { unit: 'minutes', diff: Math.floor((timeDifference % HOUR_IN_MS) / MINUTE_IN_MS) },
  ];
};

/**
 * Returns an array of the difference and the unit to later join as a string.
 * E.g
 * - ['2', 'hours']
 * - ['1', 'week']
 */
const getRelativeTimeParts = (diff: number, unit: TimeUnit): [string, string] => {
  const relativeTimeFormatter = new Intl.RelativeTimeFormat('en', { style: 'long' });
  const parts = relativeTimeFormatter.formatToParts(diff, unit).map(({ value }) => value);
  // When it's a future date expected parts are ['in ', '<number>', '<unit>']
  if (diff > 0) {
    return [parts[1], parts[2]];
  }
  // When it's a past date expected parts are ['<number>', ' <unit> ago']
  const literalParts = parts[1].split(' ');
  return [parts[0], literalParts[1]];
};

/**
 * Returns the relative time to the given date from current time with the following granularity:
 * - Maximum of 2 units
 * - Only contiguous units are displayed together (weeks & days, days & hours - hours and minutes go individually).
 * - Exceeding minutes are not displayed (if date is in 1 hour 59 minutes, `1 hour` is returned).
 * - If the date is in the past an empty string is returned.
 * - Examples:
 *    - '3 weeks 1 day'
 *    - '1 week'
 *    - '6 days 23 hours'
 *    - '1 day 1 hour'
 *    - '3 hours'
 *    - '52 minutes'
 * @throws {Error}
 */
export const getFormattedRemainingTimeInterval = (futureDate: Date) => {
  const now = Date.now();
  const timeDifference = futureDate.getTime() - now;

  // As a safeguard prevent past dates to be considered.
  if (timeDifference < 0) {
    throw new RangeError('Expected date should be in the future.');
  }

  const timeDiffs = getOrderedTimeDifferences(timeDifference);

  for (let i = 0; i < timeDiffs.length; i++) {
    const { unit, diff } = timeDiffs[i];

    let result: string[] = [];
    // Time difference is only relevant if greater than 0
    if (diff > 0) {
      result = getRelativeTimeParts(diff, unit);
      if (
        // Only 'weeks & days' and 'days & hours' are put together.
        ['weeks', 'days'].includes(unit) &&
        // There is a 'next' unit (either days or hours)
        i + 1 < timeDiffs.length &&
        // The diff is not 0 (so it's relevant)
        timeDiffs[i + 1].diff > 0
      ) {
        const nextTimeDiff = timeDiffs[i + 1];
        const nextParts = getRelativeTimeParts(nextTimeDiff.diff, nextTimeDiff.unit);
        result = result.concat([' ', ...nextParts]);
      }

      return result.join('');
    }
  }

  return '';
};

/**
 * Returns the relative time to the given date from current time with the following granularity:
 * - Maximum of 2 units
 * - Only contiguous units are displayed together (weeks & days, days & hours - hours and minutes go individually).
 * - Exceeding minutes are not displayed (if date was 1 hour 59 minutes ago, `1 hour` is returned).
 * - If the date is in the future an empty string is returned.
 * - Examples:
 *    - '3 weeks 1 day'
 *    - '1 week'
 *    - '6 days 23 hours'
 *    - '1 day 1 hour'
 *    - '3 hours'
 *    - '52 minutes'
 * @throws {Error}
 */
export const getFormattedPastTimeInterval = (pastDate: Date) => {
  const now = Date.now();
  const timeDifference = now - pastDate.getTime();

  // As a safeguard prevent future dates to be considered.
  if (timeDifference < 0) {
    throw new RangeError('Expected date should be in the past.');
  }

  const timeDiffs = getOrderedTimeDifferences(timeDifference);

  // Check if the time difference is less than a minute
  if (timeDifference < MINUTE_IN_MS) {
    return 'few seconds';
  }

  for (let i = 0; i < timeDiffs.length; i++) {
    const { unit, diff } = timeDiffs[i];

    let result: string[] = [];
    // Time difference is only relevant if greater than 0
    if (diff > 0) {
      result = getRelativeTimeParts(diff, unit);
      if (
        // Only 'weeks & days' and 'days & hours' are put together.
        ['weeks', 'days'].includes(unit) &&
        // There is a 'next' unit (either days or hours)
        i + 1 < timeDiffs.length &&
        // The next diff is different from 0
        timeDiffs[i + 1].diff > 0
      ) {
        const nextTimeDiff = timeDiffs[i + 1];
        const nextParts = getRelativeTimeParts(nextTimeDiff.diff, nextTimeDiff.unit);
        result = result.concat([' ', ...nextParts]);
      }

      return result.join('');
    }
  }

  return '';
};

/**
 * Check if the cluster version exists and its equal or greater than the expected version (major & minor).
 * v1.3.0 parsed as 1.3 and 0.1.3 as 0.1.
 * E.g:
 * - isAtLeastVersion('1.5', 'v1.4.2') => false
 * - isAtLeastVersion('1.5', 'v1.7.2') => true
 */
export const isAtLeastVersion = (expectedVersion: string, clusterVersion?: string | null): boolean =>
  clusterVersion != null &&
  validate(clusterVersion) &&
  validate(expectedVersion) &&
  compare(clusterVersion, expectedVersion, '>=');
