import { queryOptions, useMutation } from '@tanstack/react-query';
import { FromPathParams, MutationOptions, QueryData, QueryOptions, queryKey, toPathParams } from './utils';
import { router } from '../../router';
import { client } from '../client';
import { queryClient } from '../query-client';

export const endpoints = {
  programmaticKeys: '/accounts/{account_id}/auth/programmatic-access-keys',
  detail: '/accounts/{account_id}/auth/programmatic-access-keys/{programmatic_access_key_id}',
} as const;

export const getProgrammaticAccessKeys = (params: FromPathParams<QueryOptions<typeof endpoints.programmaticKeys>>) =>
  queryOptions<QueryData<typeof endpoints.programmaticKeys>>({
    queryKey: queryKey.get(endpoints.programmaticKeys, params),
  });

export const useDeleteProgrammaticAccessKeyMutation = <
  TOptions extends MutationOptions<typeof endpoints.detail, 'delete'>,
  TParams extends FromPathParams<TOptions>,
>(
  params: Pick<TParams, 'account_id'>,
) =>
  useMutation({
    mutationKey: queryKey.delete(endpoints.detail, params),
    mutationFn: async (args: Pick<TParams, 'programmatic_access_key_id'>) =>
      await client.DELETE(
        endpoints.detail,
        toPathParams({
          ...params,
          ...args,
        }),
      ),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: queryKey.get(endpoints.programmaticKeys, params) });
      await router.invalidate();
    },
  });

export const useCreateProgrammaticAccessKeyMutation = <
  TOptions extends MutationOptions<typeof endpoints.programmaticKeys>,
  TParams extends FromPathParams<TOptions>,
>(
  params: Pick<TParams, 'account_id'>,
) =>
  useMutation({
    mutationKey: queryKey.post(endpoints.programmaticKeys, params),
    mutationFn: async () => {
      const { data } = await client.POST(endpoints.programmaticKeys, { ...toPathParams(params) });
      return data!;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: queryKey.get(endpoints.programmaticKeys, params) });
      await router.invalidate();
    },
  });
