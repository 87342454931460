export const isDevelopmentEnv = window.__QDRANT_CLOUD__.env === 'dev';
export const isLocalEnv = window.__QDRANT_CLOUD__.env === 'local';
export const isProductionEnv = window.__QDRANT_CLOUD__.env === 'production';
export const isStagingEnv = window.__QDRANT_CLOUD__.env === 'staging';

/**
 * Function to get a random element from an array.
 */
export function getRandomElement<T>(array: readonly T[]): T {
  const randomIndex = Math.floor(Math.random() * array.length);
  return array[randomIndex];
}
