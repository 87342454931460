import * as Sentry from '@sentry/react';
import { isDevelopmentEnv, isLocalEnv } from './utils';

export function captureException(...args: Parameters<typeof Sentry.captureException>) {
  if (!(isDevelopmentEnv || isLocalEnv)) {
    return Sentry.captureException(...args);
  }
  console.error(...args);
  return '';
}

export function isModuleNotFoundError(wat: unknown): wat is Error {
  return (
    isError(wat) &&
    (wat.name.includes('ChunkLoadError') ||
      /* https://github.com/webpack-contrib/mini-css-extract-plugin/issues/346 */
      ('code' in wat && typeof wat.code === 'string' && wat.code.includes('CHUNK_LOAD_FAILED')))
  );
}

/**
 * Checks whether given value's type is one of a few Error or Error-like
 * {@link isError}.
 *
 * @param wat A value to be checked.
 * @returns A boolean representing the result.
 */
export function isError(wat: unknown): wat is Error {
  switch (Object.prototype.toString.call(wat)) {
    case '[object Error]':
    case '[object Exception]':
    case '[object DOMException]':
      return true;
    default:
      return isInstanceOf(wat, Error);
  }
}

/**
 * Checks whether given value's type is an instance of provided constructor.
 *
 * @param wat A value to be checked.
 * @param base A constructor to be used in a check.
 * @returns A boolean representing the result.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isInstanceOf(wat: unknown, base: any) {
  try {
    return wat instanceof base;
  } catch {
    return false;
  }
}
