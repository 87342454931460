import { Direction } from '@mui/material/styles';
import { createContext, useState, useCallback, ReactNode, useMemo } from 'react';
import { merge } from 'ts-deepmerge';
import { captureException } from '../utils/error-utils';

export type CreateThemeSettings = {
  theme: 'light' | 'dark';
  direction: Direction;
  responsiveFontSizes: boolean;
};

type AppSettings = {
  banners: { welcome: boolean; hybridCloudLaunch: boolean };
  dashboardPromps: { newsletter: boolean };
  isWelcomeStepperInProgress?: boolean;
};

export type Settings = CreateThemeSettings & AppSettings;

export const initialSettings: Settings = {
  direction: 'ltr',
  theme: 'light',
  responsiveFontSizes: true,
  banners: {
    welcome: true,
    hybridCloudLaunch: true,
  },
  dashboardPromps: {
    newsletter: true,
  },
};

export const restoreSettings = (): Settings => {
  let settings: Settings = {
    ...initialSettings,
    theme: window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light',
  };

  try {
    const storedData = window.localStorage.getItem('settings');
    if (storedData) {
      const { banners, dashboardPromps, ...otherSettings } = JSON.parse(storedData) as Settings;
      settings = {
        ...settings,
        banners: {
          ...settings.banners,
          ...banners,
        },
        dashboardPromps: {
          ...settings.dashboardPromps,
          ...dashboardPromps,
        },
        ...otherSettings,
      };
    }
  } catch (err) {
    captureException(err, { level: 'info', tags: { context: 'settings', action: 'restore-settings' } });
  }

  return settings;
};

/**
 * Do not use directly in components, use `const {saveSettings} = useSettings()` instead.
 */
export const storeSettings = (settings: Partial<Settings>) => {
  try {
    window.localStorage.setItem('settings', JSON.stringify(settings));
  } catch (err) {
    captureException(err, { level: 'info', tags: { context: 'settings', action: 'store-settings' } });
  }
};

export const SettingsContext = createContext<{ settings: Settings; saveSettings: (_: Partial<Settings>) => void }>({
  settings: initialSettings,
  saveSettings: () => undefined,
});

export const SettingsProvider = ({ children }: { children: ReactNode }) => {
  const [settings, setSettings] = useState(restoreSettings());
  const saveSettings = useCallback(
    (updatedSettings: typeof settings) => {
      setSettings((state) => {
        const newSettings = merge({}, state, updatedSettings);
        storeSettings(newSettings);
        return newSettings;
      });
    },
    [setSettings],
  );
  const value = useMemo(
    () => ({
      settings,
      saveSettings,
    }),
    [settings, saveSettings],
  );
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
  return <SettingsContext.Provider value={value as any}>{children}</SettingsContext.Provider>;
};

export const SettingsConsumer = SettingsContext.Consumer;
