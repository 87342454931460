import { z, ZodType } from 'zod';
import { MAX_NODES, MIN_NODES } from '../components/Clusters/ClusterSetup/ClusterConfiguration/ClusterNodesControl';
import { getDefaultRegion } from '../components/Clusters/ClusterSetup/ClusterRegionList';
import { ClusterTemplateType } from '../components/Clusters/ClusterSetup/ClusterTemplateList';
import { CLOUD_PROVIDER_MAP } from '../components/Clusters/constants';
import { getRandomFreeTierCloudProvider } from '../components/Clusters/helpers';
import { CloudProvider, CloudRegion } from '../services/clustersApi';

function fallback<T>(value: T): ZodType<T> {
  return z.any().transform(() => value);
}

const template = ['free', 'standard'] as const satisfies readonly ClusterTemplateType[];
const cloudProviders = [
  CLOUD_PROVIDER_MAP.AWS,
  CLOUD_PROVIDER_MAP.GCP,
  CLOUD_PROVIDER_MAP.AZURE,
  CLOUD_PROVIDER_MAP.PRIVATE,
] as const satisfies readonly CloudProvider[];

export const clusterSetupSearchSchema = z.object({
  pid: z.string().optional(),
  nodes: z.coerce
    .number()
    .min(MIN_NODES)
    .optional()
    .or(fallback(MIN_NODES))
    .pipe(z.number().max(MAX_NODES).optional().or(fallback(MAX_NODES))),
  disk: z.coerce.number().min(0).optional().or(fallback(0)),
});

export type ClusterSetupSearchParams = z.infer<typeof clusterSetupSearchSchema>;

export const clusterCreateSearchSchema = z
  .object({
    name: z.coerce.string().optional(),
    template: z.enum(template).catch(template[1]),
    provider: z.enum(cloudProviders).catch(getRandomFreeTierCloudProvider()),
    region: z
      .string()
      .catch(undefined as unknown as string)
      .transform((region) => region as CloudRegion),
    hybridCloudEnv: z.string().optional(),
  })
  .transform(({ provider, region, ...rest }) => {
    const result = {
      provider,
      region: getDefaultRegion(provider, region),
      ...rest,
    };
    if (provider !== CLOUD_PROVIDER_MAP.PRIVATE) {
      const { hybridCloudEnv, ...rest } = result;
      return rest as typeof result;
    }
    return result;
  });

export type ClusterCreateSearchParams = z.infer<typeof clusterCreateSearchSchema>;

const clusterCreateSearchDefaultParams: Partial<ClusterCreateSearchParams> = {
  template: 'standard',
};

/**
 * Parses and validates the provided search parameters for cluster creation.
 *
 * @param {string} [params.template='standard'] - The template type for the cluster. Defaults to 'standard'.
 */
export function clusterCreateSearch(params = clusterCreateSearchDefaultParams): ClusterCreateSearchParams {
  return clusterCreateSearchSchema.parse({ ...clusterCreateSearchDefaultParams, ...params });
}

export const rootSearchSchema = z.object({
  /**
   * Used for storing the suger transactional ID in a cookie to later assign it to his account.
   */
  sugerEntitlementId: z.string().optional(),
  /**
   * Authentication error to display to the user on after log-out.
   */
  aerr: z.string().optional(),
  /**
   * Used for redirection to SSO login.
   */
  sso_connection: z.string().optional(),
  /**
   * Used for redirection to SSO login.
   */
  sso_login_hint: z.string().optional(),
  /**
   * Uses to store the url where to forward the user after login.
   */
  return_to: z.string().optional(),
});

export const accountSearchSchema = z.object({
  /**
   * Stripe Session ID.
   */
  session_id: z.string().optional(),
});

export const hybridCloudSearchSchema = z.object({
  showCleanUpCommands: z.literal<boolean>(true).optional().catch(undefined),
});
