/* eslint-disable no-restricted-imports, @typescript-eslint/naming-convention */
import { useAuth0 } from '@auth0/auth0-react';
import { RouterProvider, createRouter } from '@tanstack/react-router';
import { memo } from 'react';
import { ProfileRouteMask, routeTree } from './routeTree';
import { queryClient } from '../api/query-client';
import { DefaultNotFoundComponent } from '../components/Common/DefaultNotFoundComponent';
import { LoadingIndicator } from '../components/Common/LoadingIndicator';
import { AuthContext } from '../routes/__root';
import { store } from '../store';
import { AccountPrivilege } from '../utils/constants/access-control';

export const router = createRouter({
  routeTree,
  routeMasks: [ProfileRouteMask],
  context: {
    queryClient,
    store,
    auth: {} as AuthContext,
  },
  // defaultPendingMinMs: 500,
  // defaultPreload: 'intent',
  defaultPreloadStaleTime: 0,
  // By default, TanStack Router will show a pending component for loaders that take longer than 1 second to resolve.
  defaultPendingComponent: LoadingIndicator,
  defaultNotFoundComponent: DefaultNotFoundComponent,
  // Note: do not specify a default error component, use the createRouteErrorComponent instead.
  defaultErrorComponent: undefined,
});

// TODO - This Schema Component should come from the OpenAPI spec
type AccountPermissions = {
  authentication: (
    | 'read:programmatic_access_keys'
    | 'write:programmatic_access_keys'
    | 'delete:programmatic_access_keys'
    | 'read:api-keys'
    | 'write:api-keys'
    | 'delete:api-keys'
    | 'write:roles'
    | 'read:roles'
    | 'delete:roles'
  )[];
  cluster: (
    | 'read:clusters'
    | 'write:clusters'
    | 'delete:clusters'
    | 'admin:schedules'
    | 'read:schedules'
    | 'write:schedules'
    | 'delete:schedules'
  )[];
  payment: ('read:payment_information' | 'write:payment_information')[];
  account: (
    | 'write:accounts'
    | 'read:accounts'
    | 'delete:accounts'
    | 'write:invites'
    | 'read:invites'
    | 'delete:invites'
    | 'write:users'
    | 'read:users'
    | 'read:account_users'
    | 'delete:account_users'
  )[];
};

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
  interface HistoryState {
    returnTo?: string;
  }
  interface StaticDataRouteOption {
    privileges?: AccountPrivilege[];
    permissions?: AccountPermissions[keyof AccountPermissions][number][];
  }
}

export const Router = memo(function Router() {
  const { isLoading, user, ...auth } = useAuth0();

  if (isLoading) {
    return <LoadingIndicator />;
  }
  return <RouterProvider router={router} context={{ auth: { user: user!, ...auth } }} />;
});
